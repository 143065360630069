class AccordionItems {
  constructor(el) {
    this.el = el;
    this.elItems = this.el.querySelectorAll(".accordion-item");
    for (let item of this.elItems) {
      new AccordionItem(item, this.el.dataset.type);
    }
  }
}

class AccordionItem {
  constructor(el, type) {
    this.el = el;
    this.type = type;
    this.elButton = this.el.querySelector(".accordion-item__button");
    this.elPanel = this.el.querySelector(".accordion-item__panel");
    this.elButtonIcon = this.el.querySelector(".accordion-item__button-icon");
    this.elButtonIconSvg = this.el.querySelector(".accordion-item__button-icon .delete");

    this.handleResize = this.onResize.bind(this);

    this.elButton.addEventListener("click", this.toggleAccordion.bind(this), false);
    
    window.addEventListener("resize", this.handleResize);
  }

  toggleAccordion(ev) {
    let accordionExpanded = this.elButton.getAttribute("aria-expanded") === "true";
    if (!accordionExpanded) {
      this.openAccordion();
    } else {
      this.closeAccordion();
    }
  }

  openAccordion() {
    this.elPanel.style.transition = "height .25s ease-in-out, opacity .5s linear";
    this.elPanel.style.height = this.elPanel.scrollHeight + "px";
    this.elPanel.style.opacity = "1";
    //this.el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    this.el.classList.add("expanded");
    this.elButton.setAttribute("aria-expanded", true);
    this.elButtonIconSvg.style.display = "none";
    this.elButtonIcon.style.animation = "";
  }

  closeAccordion() {
    this.elPanel.style.transition = "height .25s linear, opacity .1s linear";
    this.elPanel.style.opacity = "0";
    this.elPanel.style.height = "0";
    this.elButton.setAttribute("aria-expanded", false);
    this.el.classList.remove("expanded");
    this.elButtonIcon.style.animation = "";
    this.elButtonIconSvg.style.display = "block";
  }

  onResize() {
    if (this.elButton.getAttribute("aria-expanded") === "true") {
      this.elPanel.style.height = this.elPanel.scrollHeight + "px";
    }
  }
}

const initAccordions = () => {
  const accordions = document.querySelectorAll(".js-accordion");

  if (accordions) {
    for (let accordion of accordions) {
      new AccordionItems(accordion);
    }
  }
};

initAccordions();