import ArticlesContainer from "../articles-container/articles-container";
import Modal from "../modal/modal";

const SPAIN_STORAGE_ITEM = "spainMapDisplay",
  WORLD_STORAGE_ITEM = "worldMapDisplay",
  STEELWORK_STORAGE_ITEM = "steelWorkMapDisplay";
class MapFilterTrigger {
  el: HTMLElement;
  type: string;
  trigger: HTMLElement | undefined;
  tooltip: HTMLElement | undefined;
  tooltipCloseButton: HTMLElement | undefined;
  tooltipAcceptButton: HTMLElement | undefined;
  tooltipCheck: HTMLElement | undefined;
  tooltipDisplayCheckbox: HTMLElement | undefined;
  tooltipDisplay: boolean;
  mapTitle: string;
  regionTitle: string;
  modal: Modal;

  constructor(el: HTMLElement, modal: Modal) {
    this.el = el;
    this.type = this.el.dataset.type;
    this.mapTitle = this.el.dataset.mapTitle;
    this.regionTitle = this.el.dataset.regionTitle;
    this.modal = modal;
    this.trigger =
      (this.el.querySelector(".js-map-filter-trigger-button") as HTMLElement) ||
      undefined;
    this.tooltip =
      (this.el.querySelector(".js-map-filter-tooltip") as HTMLElement) ||
      undefined;
    this.tooltipCloseButton =
      (this.el.querySelector(".js-map-filter-tooltip-close") as HTMLElement) ||
      undefined;
    this.tooltipAcceptButton =
      (this.el.querySelector(".js-map-filter-tooltip-accept") as HTMLElement) ||
      undefined;
    this.tooltipDisplayCheckbox =
      (this.el.querySelector("input[name='tooltip-display']") as HTMLElement) ||
      undefined;

    if (this.trigger) {
      this.trigger.addEventListener("click", this.openFilter);
    }

    if (this.tooltipCloseButton && this.tooltipAcceptButton && this.tooltip) {
      this.tooltipCloseButton.addEventListener("click", this.closeTooltip);
      this.tooltipAcceptButton.addEventListener("click", this.closeTooltip);
    }

    if (this.tooltipDisplayCheckbox) {
      this.tooltipDisplayCheckbox.addEventListener(
        "change",
        function (ev: Event) {
          const el = ev.target as HTMLInputElement;
          this.tooltipDisplay = !el.checked;
        }.bind(this)
      );

      const openTooltip = localStorage.getItem(this.getLocalStorageItemName())
        ? localStorage.getItem(this.getLocalStorageItemName()) === "true"
        : "true";

      if (openTooltip) {
        this.openTooltip();
      }
    }
  }

  private openFilter = () => {
    const filter = this.trigger?.dataset.target;
    if (filter) {
      this.modal.setContentWithNext(
        filter,
        (modalContent: HTMLElement) => {
          this.modal.enableBackButton();
          if (this.trigger.dataset.type === "territory") {
            this.initTerrioriesButtons(modalContent);
          } else if (this.trigger.dataset.type === "area") {
            this.initAreasButtons(modalContent);
          }
        },
        this.trigger.dataset.type === "territory"
          ? "region-section"
          : "region-section"
      );
      this.modal.open();
    }
  };

  private initAreasButtons = (modalContent: HTMLElement) => {
    const areas = modalContent.querySelectorAll(
      ".js-steelwork-map-regions button"
    );
    areas.forEach((area) => {
      area.addEventListener(
        "click",
        function (ev: Event) {
          const el = ev.target as HTMLElement;
          const targetArea = `#region-${el.dataset.area}`;
          this.modal.setContentWithNext(
            targetArea,
            (modalContent: HTMLElement) => {
              new ArticlesContainer(modalContent, this.modal, "0", () => {
                this.modal.enableBackButton(
                  this.regionTitle,
                  this.trigger?.dataset.target,
                  (modalContent: HTMLElement) => {
                    this.modal.enableBackButton(this.mapTitle);
                    this.initAreasButtons(modalContent);
                  }
                );
              });
            },
            "region-section"
          );
        }.bind(this)
      );
    });

    modalContent.classList.add("steelwork-map__filter--inModal");
  };

  private initTerrioriesButtons = (modalContent: HTMLElement) => {
    const territories = modalContent.querySelectorAll(
      ".js-map-filter-regions button"
    );

    territories.forEach((territory) => {
      territory.addEventListener(
        "click",
        function (ev: Event) {
          const el = ev.target as HTMLElement;
          const targetRegion = `#region-${el.dataset.region}`;
          this.modal.setContentWithNext(
            targetRegion,
            (modalContent: HTMLElement) => {
              new ArticlesContainer(modalContent, this.modal, "0", () => {
                this.modal.enableBackButton(
                  this.regionTitle,
                  this.trigger?.dataset.target,
                  (modalContent: HTMLElement) => {
                    this.modal.enableBackButton(this.mapTitle);
                    this.initTerrioriesButtons(modalContent);
                  }
                );
              });
            },
            "region-section"
          );
        }.bind(this)
      );
    });

    modalContent.classList.add("map-filter--inModal");
  };

  private closeTooltip = () => {
    this.tooltip?.classList.add("map-filter-trigger__tooltip--closed");
    //this.tooltip?.parentElement?.classList.add("map-filter-trigger__tooltip--closed");
    this.setLocalStorage();
  };

  private openTooltip = () => {
    this.tooltip?.classList.remove("map-filter-trigger__tooltip--closed");
  };

  private setLocalStorage = () => {
    localStorage.setItem(
      this.getLocalStorageItemName(),
      this.tooltipDisplay?.toString() ?? "true"
    );
  };
  private getLocalStorageItemName = (): string => {
    let itemName = "";
    if (this.type === "spain") {
      itemName = SPAIN_STORAGE_ITEM;
    } else if (this.type === "world") {
      itemName = WORLD_STORAGE_ITEM;
    } else if (this.type === "steelwork") {
      itemName = STEELWORK_STORAGE_ITEM;
    }
    return itemName;
  };
}
export default MapFilterTrigger;
