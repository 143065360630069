import ImagesGallery from "../images-gallery/images-gallery";
import Modal from "../modal/modal";

class CompanyArticle {
  el: HTMLElement;
  modal: Modal;
  imagesGalleryEl: HTMLElement;

  constructor(el: HTMLElement, modal: Modal) {
    this.el = el;
    this.modal = modal;

    this.imagesGalleryEl = this.el.querySelector(
      ".js-images-gallery"
    ) as HTMLElement;

    if (this.imagesGalleryEl) {
      new ImagesGallery(this.imagesGalleryEl as HTMLElement);
    }
  }
}

export default CompanyArticle;
