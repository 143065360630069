import ArticlesContainer from "../articles-container/articles-container";
import MapFilterTrigger from "../map-filter-trigger/map-filter-trigger";
import Modal from "../modal/modal";

class SteelworkMap {
  el: HTMLElement;
  modal: Modal;
  regions: NodeListOf<Element>;
  mapAreas: NodeListOf<Element>;
  mapFilterTrigger: MapFilterTrigger;

  constructor(el: HTMLElement) {
    this.el = el;
    this.modal = Modal.getInstance();
    this.regions = this.el.querySelectorAll(".js-steelwork-map-regions button");
    this.mapAreas = this.el.querySelectorAll(
      ".js-steelwork-map-image svg path"
    );
    const mapFilterTriggerEl = this.el.querySelector(
      ".js-map-filter-trigger"
    ) as HTMLElement;
    if (mapFilterTriggerEl) {
      this.mapFilterTrigger = new MapFilterTrigger(
        mapFilterTriggerEl,
        this.modal
      );
    }

    this.regions.forEach((region) => {
      region.addEventListener(
        "mouseover",
        function (ev: Event) {
          const el = ev.target as HTMLElement;
          const area = document.querySelector(
            `#${el.dataset.area}`
          ) as HTMLElement;

          this.setStyle(area);
        }.bind(this)
      );
      region.addEventListener(
        "mouseout",
        function (ev: Event) {
          const el = ev.target as HTMLElement;
          const area = document.querySelector(
            `#${el.dataset.area}`
          ) as HTMLElement;
          this.removeStyle(area);
        }.bind(this)
      );
      region.addEventListener(
        "click",
        function (ev: Event) {
          const el = ev.target as HTMLElement;
          const target = `#region-${el.dataset.area}`;
          this.setModalContent(el, target);
        }.bind(this)
      );
    });

    this.mapAreas.forEach((area: HTMLElement) => {
      area.addEventListener(
        "mouseover",
        function (ev: Event) {
          const el = ev.target as HTMLElement;
          this.setStyle(el);
        }.bind(this)
      );
      area.addEventListener(
        "mouseout",
        function (ev: Event) {
          const el = ev.target as HTMLElement;
          this.removeStyle(el);
        }.bind(this)
      );
      area.addEventListener(
        "click",
        function (ev: Event) {
          const el = ev.target as HTMLElement;
          const target = `#region-${el.id}`;
          this.setModalContent(el, target);
        }.bind(this)
      );
    });
  }
  private setStyle(el: HTMLElement) {
    el.style.opacity = "0.55";
  }

  private removeStyle(el: HTMLElement) {
    el.style.opacity = "0";
  }

  private setModalContent(el: HTMLElement, target: string) {
    const targetRegion = this.modal.setContentWithNext(
      target,
      (modalContent: HTMLElement) => {
        new ArticlesContainer(modalContent, this.modal);
      },
      "region-section"
    );
    this.modal.open();
  }
}
export default SteelworkMap;

const initSteelworkMap = () => {
  const steelworkMap = document.querySelector(
    ".js-steelwork-map"
  ) as HTMLElement;

  if (steelworkMap) {
    new SteelworkMap(steelworkMap);
  }
};

initSteelworkMap();
