(function () {
  var doc = document.documentElement;
  var w = window;

  var prevScroll = w.scrollY || doc.scrollTop;
  var curScroll;
  var direction = 0;

  var header = document.getElementById("site-header");

  var checkScroll = function () {
    curScroll = w.scrollY || doc.scrollTop;

    if (curScroll > prevScroll) {
      direction = 2; // Scrolling down
    } else if (curScroll < prevScroll) {
      direction = 1; // Scrolling up
    }

    toggleHeader(direction, curScroll);

    prevScroll = curScroll;
  };

  var toggleHeader = function (direction, curScroll) {
    if (direction === 1 && curScroll < 84) {
      header.classList.remove("show", "hide", "scroll");
    } else if (direction === 2 && curScroll > 84) {
      header.classList.remove("show");
      header.classList.add("hide");
      header.classList.add("scroll");
      const tabs = document.querySelector(".tabs-scroll__anchors");
      if (tabs) {
        tabs.style.top = "0";
      }
    } else if (direction === 1 && curScroll > 84) {
      header.classList.remove("hide");
      header.classList.add("show");
      const tabs = document.querySelector(".tabs-scroll__anchors");
      if (tabs) {
        tabs.style.top = "85px";
      }
    }
  };

  window.addEventListener("scroll", checkScroll);
})();

class SideMenu {
  el;
  buttonOpen;
  buttonClose;

  constructor(sideMenu) {
    this.el = sideMenu;
    this.buttonOpen = document.querySelector(".button-side-menu");
    this.buttonClose = this.el.querySelector(".button-close");
    this.menuItems = this.el.querySelectorAll(".menu-item > a");
    this.menuDropdown = this.el.querySelectorAll(".menu-item-has-children > a");
    this.bindListeners();
  }

  bindListeners() {
    this.buttonOpen.addEventListener("click", this.open.bind(this));
    this.buttonClose.addEventListener("click", this.close.bind(this));
    this.menuItems.forEach((menuItem) => {
      menuItem.addEventListener("click", this.close.bind(this));
    });
  }

  open() {
    this.el.style.transition =
      "transform .6s cubic-bezier(0.215, 0.610, 0.355, 1.000)";
    this.el.classList.add("open");

    this.menuDropdown.forEach((itemDropdown) => {
      itemDropdown.setAttribute("tabindex", -1);
    });
  }

  close() {
    this.el.style.transition =
      "transform .6s cubic-bezier(0.215, 0.610, 0.355, 1.000)";
    this.el.classList.remove("open");
  }
}

const sideMenu = document.getElementById("side-menu");
if (sideMenu) {
  new SideMenu(sideMenu);
}

class subMenu {
  constructor(selector) {
    this.dropdown = document.querySelector(selector);
    this.button = this.dropdown.querySelector(".menu-item-has-children > a");
    this.menu = this.dropdown.querySelector(".sub-menu");

    this.init();
  }

  init() {
    this.button?.addEventListener("click", (event) => this.toggleMenu(event));
    if (this.dropdown && this.button && this.menu) {
      document.addEventListener("click", (event) =>
        this.handleDocumentClick(event)
      );
    }
  }

  toggleMenu(event) {
    event.preventDefault();
    event.stopPropagation();
    this.button.classList.toggle("pressed");
    this.menu.classList.toggle("show");
  }

  handleDocumentClick(event) {
    if (!this.dropdown.contains(event.target)) {
      this.button.classList.remove("pressed");
      this.menu.classList.remove("show");
    }
  }
}

document.addEventListener("DOMContentLoaded", () => {
  new subMenu("#menu-primary");

  //Ocultar el menú si se hace el Load a mitad de página, si no se ve transparente
  const siteHeader = document.querySelector('.site-header');
  if(siteHeader && window.scrollY > 100) {
    !siteHeader.classList.contains('hide') ? siteHeader.classList.add('hide') : '';
    siteHeader.classList.contains('show') ? siteHeader.classList.remove('show') : '';
  }
});