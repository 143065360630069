import lottie from "lottie-web";
// import animationLottiePath from "../../assets/lotties/lottie-siderurgia.json?url";

// Rutas de las animaciones
const lottieImageWrap = document.querySelector(".page-images__icon");

if (lottieImageWrap !== null) {
  lottie.loadAnimation({
    container: lottieImageWrap,
    renderer: "svg",
    loop: true,
    autoplay: true,
    path: "/themes/siderex/ui/assets/lotties/lottie-siderurgia.json",
  });
}
