import CompanyArticle from "../company-article/company-article";
import ListFilter from "../list-filter/list-filter";
import MapTerritoryLeaflet from "../map-territory-leaflet/map-territory-leaflet";
import Modal from "../modal/modal";
import SteelworkCompanyArticle from "../steelwork-company-article/steelwork-company-article";

type ContainerType = "autonomy" | "world";

class ArticlesContainer {
  el: HTMLElement;
  modal: Modal;
  articles: NodeListOf<HTMLElement>;
  articlesTriggers: NodeListOf<HTMLElement>;
  filter: HTMLElement;
  map: HTMLElement;
  type: ContainerType;
  title: string;
  listFilter: ListFilter;
  callback?: () => void;

  constructor(
    el: HTMLElement,
    modal: Modal,
    selectedFilterValueIndex: string = "0",
    callback?: () => void | undefined
  ) {
    this.el = el;
    this.modal = modal;
    this.type = this.el.dataset.type as ContainerType;
    this.title = this.el.dataset.title;
    this.callback = callback;
    this.articles = this.el.querySelectorAll(".js-list-item-article");
    this.articlesTriggers = this.el.querySelectorAll(
      ".js-list-item-article .js-list-item-article-trigger"
    );

    this.filter = this.el.querySelector(".js-list-filter") as HTMLElement;
    if (this.filter) {
      this.listFilter = new ListFilter(
        this.filter,
        this.articles,
        selectedFilterValueIndex
      );
    }

    this.map = this.el.querySelector(
      ".js-map-territory-leaflet"
    ) as HTMLElement;
    if (this.map) {
      new MapTerritoryLeaflet(this.map);
    }

    if (this.callback) {
      this.callback();
    }

    this.initArticlesListeners();
  }

  private initArticlesListeners(): void {
    this.articlesTriggers.forEach((articleTrigger) => {
      articleTrigger.addEventListener(
        "click",
        function (ev: Event) {
          const el = ev.currentTarget as HTMLElement;

          let modalType = "";
          if (this.type === "autonomy") {
            modalType = "company-article";
            const article = this.modal.setContent(
              `#company-${el.dataset.company}`,
              this.callback,
              modalType
            );

            new CompanyArticle(article, this.modal);
          } else if (this.type === "world") {
            modalType = "proyect-article";
            const article = this.modal.setContent(
              `#proyect-${el.dataset.company}`,
              this.callback,
              modalType
            );
          } else if (this.type === "steelwork") {
            modalType = "steelwork-company-article";
            const article = this.modal.setContent(
              `#steelwork-company-${el.dataset.company}`,
              this.callback,
              modalType
            );

            new SteelworkCompanyArticle(article, this.modal);
          }
          this.modal.enableBackButton(
            this.title,
            `#${this.el.id}`,
            (modalContent: HTMLElement) => {
              new ArticlesContainer(
                modalContent,
                this.modal,
                this.listFilter ? this.listFilter.getFilterValue() : "",
                this.callback
              );
            },
            "region-section"
          );
        }.bind(this)
      );
    });
  }
}

export default ArticlesContainer;
