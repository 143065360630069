type MapType = "autonomy" | "world";

class MapTerritoryLeaflet implements MapTerritoryLeaflet {
  map: HTMLElement;
  type?: MapType = "autonomy";
  territory: string;
  geoJSONObj: L.GeoJSON<
    GeoJSON.GeoJsonProperties,
    GeoJSON.MultiPolygon | GeoJSON.Polygon
  >;
  mapObj: L.Map;
  spainAutonomies: GeoJSON.GeoJsonObject;
  worldContinents: GeoJSON.GeoJsonObject;

  constructor(map: HTMLElement) {
    this.init(map);
  }

  async init(map: HTMLElement) {
    const L = await import("leaflet");

    const spainAutonomiesFile = await import(
      "../../assets/maps/spain-autonomies.json"
    );
    const worldContinentsFile = await import(
      "../../assets/maps/world-continents.json"
    );

    this.spainAutonomies = {
      data: spainAutonomiesFile,
      style: {
        style: {
          fillColor: "transparent",
          weight: 1,
          opacity: 1,
          color: "#962522",
          dashArray: "3",
          fillOpacity: 0.9,
        },
      },
    };
    this.worldContinents = {
      data: worldContinentsFile,
      style: {
        style: {
          fillColor: "transparent",
          weight: 2,
          opacity: 5,
          color: "white",
          dashArray: "3",
          fillOpacity: 0.9,
        },
      },
    };

    this.map = map;
    this.type = this.map.dataset.type as MapType;
    this.territory = this.map.dataset.territory;
    this.mapObj = L.map(this.map);

    L.tileLayer(
      "https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}.png",
      {
        attribution: "©OpenStreetMap, ©CartoDB",
      }
    ).addTo(this.mapObj);

    if (this.type === "autonomy") {
      this.geoJSONObj = L.geoJson(this.spainAutonomies.data, {
        ...this.spainAutonomies.style,
      }).addTo(this.mapObj);
    } else if (this.type === "world") {
      this.geoJSONObj = L.geoJson(this.worldContinents.data, {
        ...this.worldContinents.style,
      }).addTo(this.mapObj);
    }

    const territoryData = this.getTerritoryData();

    if (territoryData.center && territoryData.zoom) {
      this.mapObj.setView(territoryData.center, territoryData.zoom);
    }
    this.mapObj.setMaxZoom(15);
    this.mapObj.setMinZoom(territoryData.zoom);
    // this.mapObj.setMaxBounds([
    //     [40.12, -3.42],
    //     [40.12, -3.42],
    // ]);
    setTimeout(() => {
      this.mapObj.invalidateSize();
    }, 100);
  }

  private getTerritoryData(): {
    center: [lat: number, lng: number];
    zoom: number;
  } {
    let data = {
      center: [0, 0],
      zoom: 0,
    } as {
      center: [lat: number, lng: number];
      zoom: number;
    };

    this.geoJSONObj.eachLayer((element: any) => {
      if (this.type === "autonomy") {
        const layerProperties = element.feature.geometry.properties;
        if (layerProperties.name === this.territory) {
          data = {
            center: layerProperties.center,
            zoom: layerProperties.zoom,
          };
        }
      } else if (this.type === "world") {
        const layerProperties = element.feature.properties;
        if (
          layerProperties.name === this.territory ||
          (this.territory === "america" &&
            (layerProperties.name === "norte-america" ||
              layerProperties.name === "sur-america"))
        ) {
          data = {
            center:
              this.territory === "america"
                ? layerProperties.parent_center
                : layerProperties.center,
            zoom:
              this.territory === "america"
                ? layerProperties.parent_zoom
                : layerProperties.zoom,
          };
        }
      }
    });
    return data;
  }
}
export default MapTerritoryLeaflet;
