import ImagesGallery from "../images-gallery/images-gallery";
import Modal from "../modal/modal";
import Tabs from "../tabs/tabs";

class SteelworkCompanyArticle {
  el: HTMLElement;
  modal: Modal;
  tabsEl: HTMLElement;
  imagesGalleryEls: NodeList;
  constructor(el: HTMLElement, modal: Modal) {
    this.el = el;
    this.modal = modal;

    this.tabsEl = this.el.querySelector(".js-tabs") as HTMLElement;

    if (this.tabsEl) {
      new Tabs(this.tabsEl as HTMLElement);
    }

    this.imagesGalleryEls = this.el.querySelectorAll(".js-images-gallery");

    if (this.imagesGalleryEls) {
      for (let imagesGalleryEl of this.imagesGalleryEls) {
        new ImagesGallery(imagesGalleryEl as HTMLElement, "all-horizontal");
      }
    }
  }
}

export default SteelworkCompanyArticle;
