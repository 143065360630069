import Modal from "../modal/modal";
import { Company, Proyect, CompanyType } from "../map/map";
import CompanyArticle from "../company-article/company-article";

type MapType = "spain" | "world";

class LeafletMap implements LeafletMap {
  private modal: Modal;
  private type?: MapType = "spain";
  private map: HTMLElement;
  private geoJSONObj: L.GeoJSON<
    GeoJSON.GeoJsonProperties,
    GeoJSON.MultiPolygon | GeoJSON.Polygon
  > | null = null;
  private mapObj: L.Map | undefined;
  private markers: object[] = [];
  private companies: Company[] | undefined;
  private proyects: Proyect[] | undefined;
  private steelworksMarkerIcon: any = undefined;
  private steelTransformerMarkerIcon: any = undefined;
  private proyectMarkerIcon: any = undefined;
  private spainAutonomies: GeoJSON.GeoJsonObject | undefined;
  private worldContinents: GeoJSON.GeoJsonObject | undefined;
  private L: any;

  constructor(
    modal: Modal,
    map: HTMLElement,
    companies?: Company[],
    proyects?: Proyect[],
    type?: MapType
  ) {
    this.modal = modal;
    this.map = map;
    this.companies = companies;
    this.proyects = proyects;
    this.type = type;
    this.mapObj = undefined;
    this.init();
  }

  async init() {
    this.L = await import("leaflet");

    const spainAutonomiesFile = await import(
      "../../assets/maps/spain-autonomies.json"
    );
    const worldContinentsFile = await import(
      "../../assets/maps/world-continents.json"
    );

    this.steelworksMarkerIcon = this.L.icon({
      iconUrl: "/themes/siderex/ui/assets/steelwork-marker.png",
      iconSize: [30, 30],
      iconAnchor: [30, 30],
      popupAnchor: [-15, -30],
    });
    this.steelTransformerMarkerIcon = this.L.icon({
      iconUrl: "/themes/siderex/ui/assets/steeltransformer-marker.png",
      iconSize: [30, 30],
      iconAnchor: [30, 30],
      popupAnchor: [-15, -30],
    });
    this.proyectMarkerIcon = this.L.icon({
      iconUrl: "/themes/siderex/ui/assets/proyect-marker.png",
      iconSize: [30, 30],
      iconAnchor: [30, 30],
      popupAnchor: [-15, -30],
    });

    this.spainAutonomies = {
      data: spainAutonomiesFile,
      style: {
        style: {
          fillColor: "transparent",
          weight: 1,
          opacity: 1,
          color: "white",
          dashArray: "3",
          fillOpacity: 0.9,
        },
      },
    };
    this.worldContinents = {
      data: worldContinentsFile,
      style: {
        style: {
          fillColor: "transparent",
          weight: 2,
          opacity: 5,
          color: "white",
          dashArray: "3",
          fillOpacity: 0.9,
        },
      },
    };

    this.mapObj = this.L.map(this.map);

    if (!this.mapObj) return;

    this.L.tileLayer(
      "https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}.png",
      {
        attribution: "©OpenStreetMap, ©CartoDB",
      }
    ).addTo(this.mapObj);

    if (this.type === "spain") {
      this.mapObj.setView([39.479, -2.747], 6);
      this.mapObj.setMaxZoom(15);
      this.mapObj.setMinZoom(5.85);
      // this.mapObj.setMaxBounds([
      //     [40.12, -3.42],
      //     [40.12, -3.42],
      // ]);
    } else if (this.type === "world") {
      this.mapObj.setView([40.33, -3.746], 3);
    }

    this.createMarkers();

    this.setGeoJsonLayer();
  }

  private createMarkers(): void {
    this.markers = [];

    if (!this.mapObj) return;

    if (this.type === "spain") {
      this.companies?.forEach((company) => {
        const marker = this.L.marker(
          [company.coordinates.x ?? 0, company.coordinates.y ?? 0],
          {
            icon:
              company.company_type === "steelwork"
                ? this.steelworksMarkerIcon
                : this.steelTransformerMarkerIcon,
            alt: company.company_type,
          }
        ).addTo(this.mapObj as L.Map);

        this.markers.push(marker);

        marker.bindPopup(`<b>${company.name}</b>`);

        marker.addEventListener("click", () => {
          this.modal.setContent(
            `#company-${company.id}`,
            () => {
              new CompanyArticle(this.modal.getHTMLElement(), this.modal);
            },
            "company-article"
          );
          this.modal.open();
        });

        marker.addEventListener("mouseover", function () {
          marker.openPopup();
        });
      });
    } else if (this.type === "world") {
      this.proyects?.forEach((proyect) => {
        const marker = this.L.marker(
          [proyect.coordinates.x ?? 0, proyect.coordinates.y ?? 0],
          {
            icon: this.proyectMarkerIcon,
            alt: proyect.name,
          }
        ).addTo(this.mapObj as L.Map);

        this.markers.push(marker);

        marker.bindPopup(`<b>${proyect.name}</b>`);

        marker.addEventListener("click", () => {
          this.modal.setContent(
            `#proyect-${proyect.id}`,
            () => {},
            "proyect-article"
          );
          this.modal.open();
        });

        marker.addEventListener("mouseover", () => {
          marker.openPopup();
        });
      });
    }
  }

  private setGeoJsonLayer(): void {
    if (!(this.spainAutonomies && this.worldContinents)) return;

    if (this.type === "spain") {
      this.geoJSONObj = this.L.geoJson(this.spainAutonomies.data, {
        ...this.spainAutonomies.style,
        onEachFeature: (feature, layer) => {
          const pathLayer = layer as L.Path; // Type assertion para que layer tenga setStyle

          pathLayer.on("mouseover", () => {
            pathLayer.setStyle({
              fillColor: "#DCDCDC",
            });
          });

          pathLayer.on("mouseout", () => {
            pathLayer.setStyle({
              fillColor: "transparent",
            });
          });
        },
      }).addTo(this.mapObj);
    } else if (this.type === "world") {
      this.geoJSONObj = this.L.geoJson(
        this.worldContinents.data,
        this.worldContinents.style
      ).addTo(this.mapObj);
    }
  }

  public filterMarkers(companyType: CompanyType[]): void {
    this.markers.forEach((marker) => {
      if (
        marker.options.alt &&
        !companyType.includes(marker.options.alt as CompanyType)
      ) {
        marker.setOpacity(0);
      } else {
        marker.setOpacity(1);
      }
    });
  }

  public setCurrentLayerStyle(layerName: string): void {
    this.geoJSONObj?.eachLayer((element: any) => {
      const layerNameProperty =
        this.type === "spain"
          ? element.feature.geometry.properties.name
          : element.feature.properties.name;
      if (
        layerName === layerNameProperty ||
        (layerName === "america" &&
          (layerNameProperty === "norte-america" ||
            layerNameProperty === "sur-america"))
      ) {
        element.setStyle({
          fillColor: "#DCDCDC",
        });
      }
    });
  }

  public resetLayersStyle(): void {
    this.geoJSONObj?.resetStyle();
  }
}
export default LeafletMap;
