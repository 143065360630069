import MapFilterTrigger from "../map-filter-trigger/map-filter-trigger";
import MapFilter from "../map-filter/map-filter";
import LeafletMap from "../map-leaflet/map-leaflet";
import Modal from "../modal/modal";

export interface Company {
  id: string;
  company_type: CompanyType;
  name: string;
  coordinates: {
    x: number;
    y: number;
  };
  description: string;
  address: string;
  phone: string;
  email: string;
  link: string;
  files: Object[];
  images: Object[];
}

export interface Proyect {
  id: string;
  name: string;
  coordinates: {
    x: number;
    y: number;
  };
  description: string;
  country: string;
}

export type CompanyType = "steelwork" | "steel-transformer";
type MapType = "spain" | "world";

class Map {
  type?: MapType = "spain";
  leafLetMap: LeafletMap;
  mapFilter: MapFilter;
  modal: Modal;
  companies: Company[] | undefined;
  proyects: Proyect[] | undefined;
  mapFilterTrigger: MapFilterTrigger;

  constructor(
    map: HTMLElement,
    mapFilter: HTMLElement,
    mapFilterTrigger: HTMLElement,
    companies?: Company[],
    proyects?: Proyect[],
    type?: MapType
  ) {
    this.modal = Modal.getInstance();
    this.companies = companies;
    this.proyects = proyects;
    this.type = type;
    this.leafLetMap = new LeafletMap(
      this.modal,
      map,
      this.companies,
      this.proyects,
      type
    );
    this.mapFilter = new MapFilter(this.modal, mapFilter, this.leafLetMap);
    this.mapFilterTrigger = new MapFilterTrigger(mapFilterTrigger, this.modal);
  }
}
export default Map;

const initMap = () => {
  const map = document.querySelector("#map") as HTMLElement;

  if (map) {
    const leafletMap = map.querySelector(".js-map-leaflet") as HTMLElement;
    const mapFilter = map.querySelector(".js-map-filter") as HTMLElement;
    const mapFilterTrigger = map.querySelector(
      ".js-map-filter-trigger"
    ) as HTMLElement;

    let companies = undefined;
    let proyects = undefined;
    const mapType = (map.dataset.type || "spain") as MapType;
    if (mapType === "spain") {
      companies = map.dataset.companies
        ? JSON.parse(map.dataset.companies)
        : [];
    } else if (mapType === "world") {
      proyects = map.dataset.proyects ? JSON.parse(map.dataset.proyects) : [];
    }
    new Map(
      leafletMap,
      mapFilter,
      mapFilterTrigger,
      companies,
      proyects,
      mapType
    );
  }
};

initMap();
