
class SideMenu {
  el;
  buttonOpen;
  buttonClose;

  constructor(sideMenu) {
    this.el = sideMenu;
    this.buttonOpen = document.querySelector('.button-side-menu');
    this.buttonClose = this.el.querySelector('.button-close');
    this.menuItems = this.el.querySelectorAll('.menu-item');
    this.bindListeners();
  }

  bindListeners() {
    this.buttonOpen.addEventListener('click', this.open.bind(this));
    this.buttonClose.addEventListener('click', this.close.bind(this));
    this.menuItems.forEach((menuItem => {
      menuItem.addEventListener('click', this.close.bind(this));
    }));
  }

  open() {
    this.el.style.transition = "transform .6s cubic-bezier(0.215, 0.610, 0.355, 1.000)";
    this.el.classList.add('open');
  }

  close() {
    this.el.style.transition = "transform .6s cubic-bezier(0.215, 0.610, 0.355, 1.000)";
    this.el.classList.remove('open');
  }
}

const sideMenu = document.getElementById('side-menu');
if (sideMenu) {
  new SideMenu(sideMenu);
}



class LanguageDropdown {
  constructor(selector) {
      this.dropdown = document.querySelector(selector);
      this.button = this.dropdown.querySelector('.language-selector__button');
      this.menu = this.dropdown.querySelector('.language-selector__menu');

      this.init();
  }

  init() {
      this.button.addEventListener('click', (event) => this.toggleMenu(event));
      document.addEventListener('click', (event) => this.handleDocumentClick(event));
  }

  toggleMenu(event) {
      event.stopPropagation();
      const isExpanded = this.button.getAttribute("aria-expanded") === "true";

      this.button.setAttribute("aria-expanded", !isExpanded);
      this.menu.classList.toggle('show');
  }

  handleDocumentClick(event) {
      if (!this.dropdown.contains(event.target)) {
          this.menu.classList.remove('show');
      }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  new LanguageDropdown('.site-header .language-selector');
  new LanguageDropdown('.side-menu .language-selector');
});


