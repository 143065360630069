import Swiper from "swiper";
import { Mousewheel, Navigation } from "swiper/modules";
import "swiper/swiper-bundle.css";

class GallerySlider {
  constructor(el) {
    this.el = el;
    this.swiper = this.el.querySelector(".swiper");

    if (this.swiper) {
      new Swiper(this.swiper, {
        spaceBetween: 16,
        slidesPerView: 2.7,
        loop: true,
        grabCursor: true,
        // centeredSlidesBounds: true,
        // centeredSlides: true,
        slidesOffsetBefore: 20,
        slidesOffsetAfter: 20,

        breakpoints: {
          667: {
            slidesPerView: 3.9,
          },

          1024: {
            slidesPerView: 4.6,
            slidesOffsetAfter: 50,
            slidesOffsetBefore: 50,
          },
          1440: {
            slidesPerView: 4.8,
            slidesOffsetAfter: 50,
            slidesOffsetBefore: 50,
          },
          1580: {
            slidesPerView: 4.6,
            slidesOffsetAfter: 50,
            slidesOffsetBefore: 50,
          },
        },
        navigation: {
          prevEl: this.el.querySelector(".swiper-arrows .swiper-arrows__prev"),
          nextEl: this.el.querySelector(".swiper-arrows .swiper-arrows__next"),
        },
        modules: [Navigation, Mousewheel],
        on: {
          slideChangeTransitionStart: () => {
            if(this.swiper.classList.contains('swiper-transition-init')) return;
            
            this.swiper.classList.add('swiper-transition-init');
          },
        }
      });
    }
  }
}
function initGallerySliders() {
  const sliders = document.querySelectorAll(".team-gallery");
  for (let slider of sliders) {
    new GallerySlider(slider);
  }
}

initGallerySliders();
