class TabsScroll {
  constructor(el) {
    this.el = el;
    this.buttons = this.el.querySelectorAll('.tab');
    this.contents = this.el.querySelectorAll('.tabs-scroll__item');
    this.activeIndex = null;
    this.navEl = this.el.querySelector(".tabs");

    const threshold = el.classList.contains('tabs-scroll--big') ? 0.5 : 0.8;
    this.observer = new IntersectionObserver(this.handleIntersect.bind(this), {
      threshold: threshold
    });

    this.observeContents();
    this.attachEventListeners();
    this.activateFirstItem();
  }

  observeContents() {
    this.contents.forEach(content => this.observer.observe(content));
  }

  handleIntersect(entries) {
    entries.forEach(entry => {
      const index = entry.target.getAttribute('data-index');
      const correspondingButton = this.el.querySelector(`.tab[data-index="${index}"]`);

      if (entry.isIntersecting) {
        if (this.activeIndex !== index) {
          this.setActiveItem(index, correspondingButton);
        }
      }
    });
  }

  activateFirstItem() {
    if (this.buttons.length > 0) {
      const firstButton = this.buttons[0];
      const firstIndex = firstButton.getAttribute('data-index');
      this.setActiveItem(firstIndex, firstButton);
    }
  }

  setActiveItem(index, correspondingButton) {
    this.removeActiveItem();
    this.activeIndex = index;
    correspondingButton.classList.add('marked');
    const activeContent = this.el.querySelector(`.tabs-scroll__item[data-index="${index}"]`);
    activeContent.classList.add('show');
    activeContent.setAttribute("data-active", true);
    this.scrollToButton(correspondingButton);
  }

  removeActiveItem() {
    if (this.activeIndex !== null) {
      const previousButton = this.el.querySelector(`.tab[data-index="${this.activeIndex}"]`);
      const previousContent = this.el.querySelector(`.tabs-scroll__item[data-index="${this.activeIndex}"]`);
      previousContent.classList.remove('show');
      previousContent.setAttribute("data-active", false);
      previousButton.classList.remove('marked');
      this.activeIndex = null;
    }
  }

  scrollToButton(button) {
    const buttonRect = button.getBoundingClientRect();
    const navRect = this.navEl.getBoundingClientRect();
    if (buttonRect.left < navRect.left) {
      this.navEl.scrollLeft -= (navRect.left - buttonRect.left);
    } else if (buttonRect.right > navRect.right) {
      this.navEl.scrollLeft += (buttonRect.right - navRect.right);
    }
  }

  attachEventListeners() {
    this.buttons.forEach(button => {
      button.addEventListener('click', () => {
        const index = button.getAttribute('data-index');
        if (index !== null) {
          this.setActiveItem(index, button);
          const targetItem = this.el.querySelector(`.tabs-scroll__item[data-index="${index}"]`);
          if (targetItem) {
            targetItem.scrollIntoView({
              behavior: 'smooth',
              block: 'start'
            });
          }
        }
      });
    });
  }
}

function initTabsScroll() {
  const tabElements = document.querySelectorAll('.tabs-scroll--big, .tabs-scroll--small, .tabs-scroll--services');
  tabElements.forEach(el => new TabsScroll(el));
}

document.addEventListener("DOMContentLoaded", initTabsScroll);
