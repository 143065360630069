class ValueChainScheme {
  el: Element;
  options: NodeListOf<HTMLInputElement>;
  items: NodeListOf<Element>;
  links: NodeListOf<HTMLElement>;

  constructor(el: Element) {
    this.el = el;
    this.options = this.el.querySelectorAll(
      ".js-value-chain-scheme__options input"
    );
    this.items = this.el.querySelectorAll(
      ".js-value-chain-scheme__button[data-type]"
    );
    this.links = this.el.querySelectorAll(
      ".js-value-chain-scheme__link[data-type]"
    );

    this.options.forEach((option) => {
      option.addEventListener(
        "change",
        function (ev: Event) {
          const checkedValues = Array.from(this.options)
            .filter((option: any) => {
              return option.checked;
            })
            .map((option: any) => {
              return option.value;
            });
          this.updateItemsState(checkedValues);
          this.displayLinks(checkedValues);
        }.bind(this)
      );
    });
  }

  private updateItemsState(values: string[]) {
    this.items.forEach((item: HTMLElement) => {
      if (values.includes(item.dataset.type)) {
        item.classList.add(`value-chain-scheme__button--${item.dataset.color}`);
      } else {
        item.classList.remove(
          `value-chain-scheme__button--${item.dataset.color}`
        );
      }
    });
  }

  private displayLinks(values: string[]) {
    this.links.forEach((link) => {
      if (values.includes(link.dataset.type)) {
        link.classList.remove("value-chain-scheme__link--hidden");
      } else {
        link.classList.add("value-chain-scheme__link--hidden");
      }
    });
  }
}
export default ValueChainScheme;

const initValueChainScheme = () => {
  const valueChainSchemes = document.querySelectorAll(
    ".js-value-chain-scheme"
  ) as NodeListOf<Element>;
  if (valueChainSchemes) {
    valueChainSchemes.forEach((valueChainScheme) => {
      new ValueChainScheme(valueChainScheme);
    });
  }
};

initValueChainScheme();
