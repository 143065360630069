type FilterType = "radio" | "dropdown";

class ListFilter {
  el: HTMLElement;
  type: FilterType;
  filters: NodeListOf<HTMLInputElement>;
  select: HTMLSelectElement;
  targetItems: NodeListOf<HTMLElement>;
  selectedFilterValue: string;

  constructor(
    el: HTMLElement,
    targetItems: NodeListOf<HTMLElement>,
    selectedFilterValue: string = "0"
  ) {
    this.el = el;
    this.type = this.el.dataset.type as FilterType;
    this.targetItems = targetItems;
    this.selectedFilterValue = selectedFilterValue;

    if (this.type === "radio") {
      this.filters = this.el.querySelectorAll("input");
      this.filters[0].checked = true;
    } else if (this.type === "dropdown") {
      this.select = this.el.querySelector("select"); 
    }

    this.filters?.forEach((filter) => {
      if (filter.value === this.selectedFilterValue) {
        filter.checked = true;
        this.targetItems.forEach((item: HTMLElement) => {
          if (this.selectedFilterValue === "all") {
            item.classList.remove("hidden");
          } else if (this.selectedFilterValue === item.dataset.type) {
            item.classList.remove("hidden");
          } else {
            item.classList.add("hidden");
          }
        });
      }
      filter.addEventListener(
        "change",
        function (ev: Event) {
          const checkedValue = Array.from(this.filters)
            .filter((filter: any) => {
              return filter.checked;
            })
            .map((filter: any) => {
              return filter.value;
            })[0];
          // Filter LIST
          this.targetItems.forEach((item: HTMLElement) => {
            if (checkedValue === "all") {
              item.classList.remove("hidden");
            } else if (checkedValue === item.dataset.type) {
              item.classList.remove("hidden");
            } else {
              item.classList.add("hidden");
            }
          });
        }.bind(this)
      );
    });

    if (this.select && this.selectedFilterValue !== "0") {
      this.select.value = this.selectedFilterValue;
      this.targetItems.forEach((item: HTMLElement) => {
        if (this.selectedFilterValue === "all") {
          item.classList.remove("hidden");
        } else {
          if (this.selectedFilterValue === item.dataset.country) {
            item.classList.remove("hidden");
          } else {
            item.classList.add("hidden");
          }
        }
      });
    }
    this.select?.addEventListener(
      "change",
      function (ev: any) {
        this.targetItems.forEach((item: HTMLElement) => {
          const selectedValue = ev.currentTarget.value;
          if (selectedValue === "all") {
            item.classList.remove("hidden");
          } else {
            if (selectedValue === item.dataset.country) {
              item.classList.remove("hidden");
            } else {
              item.classList.add("hidden");
            }
          }
        });
      }.bind(this)
    );
  }

  public getFilterValue(): string {
    if (this.type === "radio") {
      return this.getRadioCheckedValue();
    } else {
      return this.select.value;
    }
  }

  private getRadioCheckedValue(): string {
    for (var i = 0, length = this.filters.length; i < length; i++) {
      if (this.filters[i].checked) {
        return this.filters[i].value;
      }
    }
  }
}

export default ListFilter;
