class Tabs {
  tabs: HTMLElement;
  tabsButtons: HTMLElement[];
  tabsSections: HTMLElement[];

  constructor(tabs: HTMLElement) {
    this.tabs = tabs;
    this.tabsButtons = Array.from(
      this.tabs.querySelectorAll('nav > *[role="tab"]')
    );
    this.tabsSections = Array.from(
      this.tabs.querySelectorAll('.js-tabs-sections > *[role="tabpanel"]')
    );
    this.activateFirstTab();
    for (let tabButton of this.tabsButtons) {
      tabButton.addEventListener("click", this.toggleActiveClass.bind(this));
    }
  }

  activateFirstTab() {
    let target = this.tabsButtons[0].getAttribute("aria-controls");
    this.tabsButtons[0].setAttribute("aria-selected", "true");
    this.tabs
      .querySelector(`#${target}[role="tabpanel"]`)
      .classList.add("active");
  }

  toggleActiveClass(ev: Event) {
    let target = ev.target as HTMLElement;

    let targetControl = target.getAttribute("aria-controls");
    let tabSection = this.tabs.querySelector(
      `#${targetControl}[role="tabpanel"]`
    );

    for (let tabButton of this.tabsButtons) {
      tabButton.setAttribute("aria-selected", "false");
    }
    target.setAttribute("aria-selected", "true");

    for (let tabSection of this.tabsSections) {
      tabSection.classList.remove("active");
    }
    tabSection.classList.add("active");

    if (target) {
      target.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  }
}

export default Tabs;
