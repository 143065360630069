// import Swiper JS
import Swiper from "swiper";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
// import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import Modal from "../modal/modal";

type ImageGalleryType = "default" | "all-horizontal";
class ImagesGallery {
  private el: HTMLElement;
  private type: ImageGalleryType;
  private mainSwipper: Swiper | undefined;
  private galleryModal: HTMLElement | undefined;
  private modalMainSwipper: Swiper | undefined;
  private modalThumbsSwipper: Swiper | undefined;

  constructor(el: HTMLElement, type: ImageGalleryType = "default") {
    this.el = el;
    this.type = type;
    this.galleryModal =
      (this.el.querySelector(".js-images-gallery-modal") as HTMLElement) ??
      undefined;
    this.mainSwipper = new Swiper(
      this.el.querySelector(".images-gallery__main-swiper") as HTMLElement,
      {
        spaceBetween: 10,
        slidesOffsetAfter: 20,
        slidesPerView: "auto",
        freeMode: true,
        watchSlidesProgress: true,
        modules: [Navigation, FreeMode, Thumbs],
        on: {
          click: (swiper: Swiper): void => {
            if (!this.galleryModal) return;

            const galleryClose = this.galleryModal.querySelector(
              ".js-images-gallery-modal-close"
            );
            galleryClose?.addEventListener("click", () => {
              this.galleryModal?.classList.remove(
                "images-gallery__modal--open"
              );
              Modal.getInstance().enableScroll();
            });
            this.initModalSwiper(swiper.activeIndex ?? 0);
          },
        },
        breakpoints: {
          992: {
            mousewheel: true,
            direction: this.type === "default" ? "vertical" : "horizontal",
          },
        },
      }
    );
    this.modalMainSwipper = undefined;
    this.modalThumbsSwipper = undefined;

    if (!this.mainSwipper) return;

    this.checkSingleSlide();
  }

  private checkSingleSlide() {
    const slides = this.mainSwipper?.slides;
    if (!slides) return;

    const swiperContainer = this.el.querySelector(
      ".images-gallery__main-swiper"
    );
    if (slides.length === 1) {
      swiperContainer?.classList.add("images-gallery__main-swiper--only");
    } else {
      swiperContainer?.classList.remove("images-gallery__main-swiper--only");
    }
  }

  private initModalSwiper(index: number = 0) {
    this.modalThumbsSwipper = new Swiper(
      this.el.querySelector(
        ".images-gallery__modal-swiper--thumbs .swiper"
      ) as HTMLElement,
      {
        spaceBetween: 10,
        slidesPerView: 6,
        direction: "horizontal",
        mousewheel: true,
        watchSlidesProgress: true,
        modules: [Navigation, Thumbs],
        initialSlide: index,
        navigation: {
          nextEl: ".swiper-button--next",
          prevEl: ".swiper-button--prev",
        },
      }
    );

    this.modalMainSwipper = new Swiper(
      this.el.querySelector(
        ".images-gallery__modal-swiper--main .swiper"
      ) as HTMLElement,
      {
        enabled: false,
        spaceBetween: 10,
        slidesPerView: 1,
        watchSlidesProgress: true,
        modules: [Navigation, Thumbs],
        mousewheel: true,
        centeredSlides: true,
        navigation: {
          nextEl: this.el.querySelector(".swiper-button--next") as HTMLElement,
          prevEl: this.el.querySelector(".swiper-button--prev") as HTMLElement,
        },
        initialSlide: index,
        breakpoints: {
          992: {
            enabled: true,
          },
        },
        thumbs: {
          swiper: this.modalThumbsSwipper,
        },
      }
    );

    this.galleryModal?.classList.add("images-gallery__modal--open");
    Modal.getInstance().disableScroll();
  }
}

export default ImagesGallery;
