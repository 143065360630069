import ArticlesContainer from "../articles-container/articles-container";
import LeafletMap from "../map-leaflet/map-leaflet";
import Modal from "../modal/modal";

class MapFilter {
  modal: Modal;
  filter: HTMLElement;
  map: LeafletMap;
  regions: NodeListOf<Element>;
  filters: NodeListOf<HTMLInputElement>;

  constructor(modal: Modal, mapFilter: HTMLElement, leafletMap: LeafletMap) {
    this.modal = modal;
    this.filter = mapFilter;
    this.map = leafletMap;

    this.regions = this.filter.querySelectorAll(
      ".js-map-filter-regions button"
    );
    this.filters = this.filter.querySelectorAll(".js-map-filter-filters input");

    this.regions.forEach((region) => {
      region.addEventListener(
        "mouseover",
        function (ev: Event) {
          const el = ev.target as HTMLElement;
          this.map.setCurrentLayerStyle(el.dataset.region);
        }.bind(this)
      );
      region.addEventListener("mouseout", () => this.map.resetLayersStyle());
      region.addEventListener(
        "click",
        function (ev: Event) {
          const el = ev.target as HTMLElement;
          const targetRegion = `#region-${el.dataset.region}`;
          this.modal.setContentWithNext(
            targetRegion,
            (modalContent: HTMLElement) => {
              new ArticlesContainer(modalContent, this.modal);
            },
            "region-section"
          );
          this.modal.open();
        }.bind(this)
      );
    });

    this.filters.forEach((filter) => {
      filter.addEventListener(
        "change",
        function (ev: Event) {
          const checkedValues = Array.from(this.filters)
            .filter((filter: any) => {
              return filter.checked;
            })
            .map((filter: any) => {
              return filter.value;
            });
          this.map.filterMarkers(checkedValues);
        }.bind(this)
      );
    });
  }
}

export default MapFilter;
