export type ModalType =
  | "default"
  | "company-article"
  | "steelwork-company-article"
  | "proyect-article"
  | "region-section";

class Modal {
  private static instance: Modal;

  modalWrapper: HTMLElement;
  modal: HTMLElement;
  modalTitle: HTMLElement;
  modalClose: HTMLElement;
  modalBack: HTMLElement;
  modalDialog: HTMLElement;
  modalContent: HTMLElement;
  backdrop: HTMLElement;

  private constructor() {
    this.modalWrapper = document.querySelector(
      "#js-modal-wrapper"
    ) as HTMLElement;
    if (this.modalWrapper) {
      this.modal = this.modalWrapper.querySelector("#js-modal") as HTMLElement;
      this.modalTitle = this.modal.querySelector(
        "#js-modal-title"
      ) as HTMLElement;
      this.modalClose = this.modal.querySelector(
        "#js-modal-close"
      ) as HTMLElement;
      this.modalBack = this.modal.querySelector(
        "#js-modal-back"
      ) as HTMLElement;
      this.modalContent = this.modal.querySelector(
        "#js-modal-content"
      ) as HTMLElement;
      this.backdrop = document.querySelector(
        "#js-modal-backdrop"
      ) as HTMLElement;

      this.modalClose.addEventListener("click", () => {
        this.close();
      });
      this.backdrop.addEventListener("click", () => {
        this.close();
      });
    }
  }

  public static getInstance(): Modal {
    if (!Modal.instance) {
      Modal.instance = new Modal();
    }
    return Modal.instance;
  }

  public open(): void {
    this.modal.scrollTop = 0;
    this.modal.setAttribute("open", "");
    this.modalWrapper.classList.add("modal-wrapper--open");
    document.body.classList.add("modal-open");
  }

  public close(): void {
    this.modal.removeAttribute("open");
    this.modalContent.innerHTML = "";
    this.modalWrapper.classList.remove("modal-wrapper--open");
    this.modalBack.classList.add("modal__back--hidden");
    document.body.classList.remove("modal-open");
  }

  public setContent(
    selector: string,
    callback: () => void,
    type: ModalType = "default"
  ): HTMLElement {
    this.setModalType(type);

    const modalContentEl = this.setModalContent(selector);
    if (callback) {
      callback();
    }

    return modalContentEl;
  }

  public enableBackButton(
    backText?: string,
    selector?: string,
    callback?: (modalContent: HTMLElement) => void,
    type: ModalType = "default"
  ): void {
    this.resetBackButton();

    const backTextEl = this.modalBack.querySelector(
      ".modal__back-text"
    ) as HTMLElement;
    if (backText && backTextEl) {
      backTextEl.innerText = backText;
    }
    this.modalBack.classList.remove("modal__back--hidden");
    this.modalBack.addEventListener("click", function backListenerCallback() {
      if (selector && callback) {
        Modal.getInstance().returnToPreviousContent(selector, callback, type);
      } else {
        Modal.getInstance().close();
      }
      this.removeEventListener("click", backListenerCallback);
    });
  }

  public setContentWithNext(
    selector: string,
    callback: (el: HTMLElement) => void,
    type: ModalType = "default"
  ) {
    this.setModalType(type);

    const modalContentEl = this.setModalContent(selector);
    if (callback) {
      callback(modalContentEl);
    }

    return modalContentEl;
  }

  public getHTMLElement(): HTMLElement {
    return this.modalWrapper;
  }

  public resetBackButton() {
    this.modalBack.replaceWith(this.modalBack.cloneNode(true));
    this.modalBack = this.modal.querySelector("#js-modal-back") as HTMLElement;
  }

  public enableScroll() {
    this.modalWrapper.classList.remove("modal-wrapper--scroll-disabled");
  }

  public disableScroll() {
    if (window.innerWidth >= 992) {
      this.modal.scrollTo(0, 0);
    }
    this.modalWrapper.classList.add("modal-wrapper--scroll-disabled");
  }

  private returnToPreviousContent(
    previous: string,
    callback: (el: HTMLElement) => void,
    type: ModalType = "default"
  ) {
    this.modalBack.classList.add("modal__back--hidden");
    this.setContentWithNext(previous, callback, type);
  }

  private setModalContent(selector: string): HTMLElement {
    const filteredSelector = selector.replace("_modal", "");
    const el = document.querySelector(filteredSelector) as Node;
    if (!el) {
      return null;
    }
    this.modal.scrollTop = 0;
    this.modalContent.innerHTML = "";
    const elClone = el.cloneNode(true) as HTMLElement;
    elClone.id = `${elClone.id}_modal`;
    this.modalContent.appendChild(elClone);
    return elClone;
  }

  private setModalType(type: ModalType) {
    let modalOpen = false;
    if (this.modalWrapper.classList.contains("modal-wrapper--open")) {
      modalOpen = true;
    }
    this.modalWrapper.className = `modal-wrapper modal-wrapper--${type}${
      modalOpen ? " modal-wrapper--open" : ""
    }`;
  }
}

export default Modal;
