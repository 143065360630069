import Swiper from "swiper";
import { Mousewheel, Navigation, Scrollbar } from "swiper/modules";
import "swiper/swiper-bundle.css";

class HomeSlider {
  constructor(el) {
    this.el = el;
    this.swiper = this.el.querySelector(".swiper");

    if (this.swiper) {
      new Swiper(this.swiper, {
        slidesPerView: 1,
        loop: true,
        grabCursor: true,

        // breakpoints: {
        //   667: {
        //     slidesPerView: 3.9,
        //   },

        //   1024: {
        //     slidesPerView: 4.6,
        //     slidesOffsetAfter: 50,
        //     slidesOffsetBefore: 50,
        //   },
        //   1440: {
        //     slidesPerView: 4.8,
        //     slidesOffsetAfter: 50,
        //     slidesOffsetBefore: 50,
        //   },
        // },
        navigation: {
          prevEl: this.el.querySelector(".swiper-arrows .swiper-arrows__prev"),
          nextEl: this.el.querySelector(".swiper-arrows .swiper-arrows__next"),
        },
        scrollbar: {
          el: ".swiper-scrollbar",
          hide: false,
        },
        modules: [Navigation, Scrollbar, Mousewheel],
      });
    }
  }
}
function initHomeSliders() {
  const sliders = document.querySelectorAll(".page-hero--home");
  for (let slider of sliders) {
    new HomeSlider(slider);
  }
}

initHomeSliders();
